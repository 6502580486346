import React, { useState } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  margin-top: 34px;
  font-size: 18px;
  height: 17px;
  border: 0;
  width: 100%;
  max-width: 350px;
  cursor: text;
  border-bottom: 4px solid #1d1d1b;
  padding-bottom: 8px;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: #1d1d1b;
    font-size: 14px;
  }
`;

const TextBox = styled.div`
  width: 100%;
  display: grid;

  @media (max-width: 700px) {
    max-width: 350px;
    margin: 0 auto;
  }
`;

const Text = styled.div`
  align-self: end;

  h3 {
    margin-bottom: 40px;
  }
`;

const ImageBox = styled.div`
  width: 100%;
  margin-left: -70px;
  
  img {
    width: 100%;
  }
`;

const StyledWrapper = styled.div`
  padding: 0 70px;
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 700px) {
    padding: 0 30px;
    margin-bottom: 30px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

const Actions = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  max-width: 350px;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const Forgot = styled.a`
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  color: #1d1d1b;
`;
const Log = styled.a`
  font-size: 20px !important;
  color: #1d1d1b;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  margin-top: 20px;
`;
const Buttons = styled.div``;

const LogIn = ({ data, language }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState(""); //cis 247 user.password@
  const handleUsernameChange = (event) => setUsername(event.target.value);

  const handlePasswordChange = (event) => setPassword(event.target.value);
  const translate = (key) => {
    return `${key}_${language}`;
  };

  return (
    <StyledWrapper>
      <Container>
        <ImageBox>
          <img
            src={
              !!data.sideImage?.childImageSharp
                ? data.sideImage?.childImageSharp.fluid.src
                : data.sideImage
            }
          />
        </ImageBox>
        <TextBox>
          <Text>
            <h3>log in</h3>
            <StyledInput
              onChange={handleUsernameChange}
              placeholder={data[translate("username")]}
              value={username}
              type={"text"}
            />
            <StyledInput
              onChange={handlePasswordChange}
              value={password}
              placeholder={data[translate("password")]}
              type={"password"}
            />
            <Actions>
              <Forgot href={"mailto:biuro@blokarchitekci.pl"}>
                {data[translate("forgotPassword")]}
              </Forgot>
              <Buttons>
                <Log
                  href={
                    username &&
                    password &&
                    `ftp://${username}:${password}@79.188.111.30/`
                  }
                >
                  log in
                </Log>
              </Buttons>
            </Actions>
          </Text>
        </TextBox>
      </Container>
    </StyledWrapper>
  );
};

export default LogIn;
