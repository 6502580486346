import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import LogIn from "../components/LogIn";
import { Menu } from "../components/Menu/Menu";
import { Navbar } from "../components/Navbar";

const ClientPanelPage = ({ data }) => {
  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    } else {
      setLanguage("pl");
      localStorage.setItem("language", "pl");
    }
  };
  return (
    <Layout>
      <>
        <Menu
          language={language === "pl" ? "eng" : "pl"}
          handleLanguageClick={handleLanguageClick}
        />
        <Navbar />
        <LogIn language={language} data={data.markdownRemark.frontmatter} />
      </>
    </Layout>
  );
};

export default ClientPanelPage;

export const clientPanelPageQuery = graphql`
  query ClientPanelPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heading_pl
        heading_eng
        username_pl
        username_eng
        sideImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        password_pl
        password_eng
        forgotPassword_pl
        forgotPassword_eng
      }
    }
  }
`;
